<template>
  <div class="app">
    <!-- <van-nav-bar title="电梯详情" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="ellipsis" size="20" color="#000" />
      </template>
    </van-nav-bar> -->

    <div class="min">
      <div class="banner">
        <p class="banner-one">{{ elevator.communityName }}</p>
        <p class="banner-too">{{ elevator.systemCode }}</p>
        <!-- <p class="banner-three">—</p> -->
        <!-- <div>
						<span>综合评分</span>
						5.00
						<van-rate v-model="value" readonly size="20" color="#fff" void-color="#ccc" />
					</div> -->
        <van-button v-if="wxOpenId" type="info" @click="cancelAttention">
          <!-- <img src="../../assets/add.png" alt=""> -->
          取消关注
        </van-button>

        <van-button v-else type="info" @click="attention">
          <img src="../../assets/add.png" alt="" />
          关注
        </van-button>
      </div>
      <div class="info">
        <h5>基础信息</h5>
        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/info1.png" alt="" />
            <span>应急编码</span>
            <p>{{ elevator.emergencyCode }}</p>
          </div>
          <div class="info-item">
            <img src="../../assets/info2.png" alt="" />
            <span>设备代码</span>
            <p>{{ elevator.code }}</p>
          </div>
        </div>
        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/info3.png" alt="" />
            <span>电梯品牌</span>
            <p>{{ elevator.brandName }}</p>
          </div>
          <div class="info-item">
            <img src="../../assets/info4.png" alt="" />
            <span>载重量（kg）</span>
            <p>{{ elevator.deadWeight }}</p>
          </div>
        </div>
        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/info5.png" alt="" />
            <span>限载人数（人）</span>
            <p>{{ elevator.limitNumber }}</p>
          </div>
          <div class="info-item">
            <img src="../../assets/maintenance.svg" alt="" />
            <span>上次维保日期</span>
            <p>{{ elevator.lastMaintenanceTime | formatDate() }}</p>
          </div>
        </div>
        <div class="info-outside">
          <div class="info-item">
            <img src="../../assets/maintenance.svg" alt="" />
            <span>预计下次维保日期</span>
            <p>{{ elevator.nextMaintenanceTime | formatDate() }}</p>
          </div>
          <div class="info-item">
            <img src="../../assets/info6.png" alt="" />
            <span>下次年检日期</span>
            <p>{{ elevator.annualCheckTime | formatDate() }}</p>
          </div>
        </div>
      </div>
      <!-- <h5 class="h5">使用单位</h5> -->
      <div class="main">
        <div v-if="elevator.maintenanceName" class="main-item">
          <img src="../../assets/user.png" alt="" />
          <span class="item-top">{{ elevator.maintenanceName }}</span>
          <div class="main-item-outside">
            <span class="phone">24小时值班电话</span>
            <div class="whole">
              <a :href="`tel:${elevator.workTelphone}`">
                <span class="phone-number">{{ elevator.workTelphone }}</span>
                <span class="vertical">|</span>
                <van-icon name="phone-o" color="#3369FD" size="16" />
              </a>
            </div>
          </div>
        </div>
        <div v-if="elevator.companyName" class="main-item">
          <img src="../../assets/user.png" alt="" />
          <span class="item-top">{{ elevator.companyName }}</span>
          <div class="main-item-outside">
            <span class="phone">24小时值班电话</span>
            <div class="whole">
              <a :href="`tel:${elevator.companyWorkTelphone}`">
                <span class="phone-number">{{
                  elevator.companyWorkTelphone
                }}</span>
                <span class="vertical">|</span>
                <van-icon name="phone-o" color="#3369FD" size="16" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="title">维保记录</div>
      <div
        class="info"
        v-for="item in list"
        :key="item.id"
        style="margin-top: 5px; margin-bottom: 10px; padding-top: 2px"
        @click="goInfo(item.id)"
      >
        <div class="info-outside">
          <div class="info-item">
            <span>维保类型</span>
            <p>{{ getMaintenanceType(item.maintenanceItemName) }}</p>
          </div>
          <div class="info-item" style="margin-left: 10px">
            <span>完成时间</span>
            <p>{{ item.finishTime | formatDate() }}</p>
          </div>
        </div>
      </div>
      <div
        v-show="list.length == 0"
        class="info"
        style="
          text-align: center;
          font-size: 16px;
          margin-bottom: 10px;
          padding: 30px;
        "
      >
        暂无维保记录
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import {
  getElevatorDetail,
  attention,
  cancelAttention,
  isAttentionPub,
  getWxUserInfo
} from '../../api/public'
import { getWxUser, setWxUser } from '../../utils'
export default {
  data () {
    return {
      value: 5,
      elevator: {
        communityName: '',
        systemCode: '',
        emergencyCode: '',
        code: '',
        brandName: '',
        deadWeight: '',
        limitNumber: '',
        lastMaintenanceTime: '',
        nextMaintenanceTime: '',
        annualCheckTime: '',
        maintenanceName: '',
        workTelphone: '',
        companyName: '',
        companyWorkTelphone: ''
      },
      list: [],
      wxuserInfo: '',
      winUrl: '',
      code: '',
      elevatorId: '',
      wxOpenId: '',
      wxUserNickName: '',
      cancelId: '',
      isPub: false // 是否关注公众号
    }
  },
  created () {
    this.elevatorId = this.$route.query.id
    this.wxuserInfo = getWxUser()
    this.getDatail()
    this.isAttentionPub()
  },
  methods: {
    onClickLeft () {
      this.$router.push('theQueryDt')
      // console.log(11)
    },
    // 维保类型字典值
    getMaintenanceType (value) {
      if (value === 'HALF_MONTH') {
        return '半月保'
      } else if (value === 'QUARTER') {
        return '季度保'
      } else if (value === 'HALF_YEAR') {
        return '半年保'
      } else if (value === 'YEAR') {
        return '年度保'
      }
    },
    getDatail () {
      try {
        const query = {
          elevatorId: this.elevatorId
        }

        console.info(this.wxuserInfo.openid)

        if (this.wxuserInfo.openid) {
          Object.assign(query, {
            wxOpenId: this.wxuserInfo.openid
          })
        }

        getElevatorDetail(query).then((response) => {
          if (
            response.data.maintenanceOrderList != null ||
            response.data.maintenanceOrderList.length > 0
          ) {
            this.list = response.data.maintenanceOrderList
          }

          this.elevator = response.data.elevatorResult
          if (response.data.attentionResult) {
            this.wxOpenId = response.data.attentionResult.wxOpenId
            this.wxUserNickName = response.data.attentionResult.wxUserNickName
            this.cancelId = response.data.attentionResult.id
          }
          if (this.elevator == null) {
            this.$router.push({
              path: 'nullData'
            })
          }
        })
      } catch (error) {}
    },

    // 关注电梯
    attention () {
      try {
        if (this.wxuserInfo.openid) {
          const params = {
            elevatorId: this.elevator.id,
            wxOpenId: this.wxuserInfo.openid,
            wxUserNickName: this.wxuserInfo.nickname
          }
          // console.info(params);
          attention(params).then((response) => {
            if (response.resultCode == 200) {
              Toast.success('关注成功')
              this.wxOpenId = this.wxuserInfo.openid
              this.wxUserNickName = this.wxuserInfo.nickname
              this.cancelId = response.data.id
              if (!this.isPub) {
                Dialog.alert({
                  message:
                    '您还未关注”广西电梯云平台“公众号, 关注才可以接收电梯消息哦',
                  theme: 'round-button',
                  confirmButtonText: '去关注'
                }).then(() => {
                  this.notAttention()
                })
              }
            } else {
              Toast.fail('关注失败')
            }
          })
        } else {
          this.getCode()
        }
      } catch (error) {
        console.info(error)
      }
    },
    // 取消关注
    cancelAttention () {
      try {
        const params = {
          id: this.cancelId,
          wxOpenId: this.wxOpenId
        }
        cancelAttention(params).then((response) => {
          if (response.resultCode == 200) {
            Toast.success('取消成功')
            this.wxOpenId = ''
            this.cancelId = ''
            this.wxUserNickName = ''
          } else {
            Toast.fail('取消失败')
          }
        })
      } catch (error) {
        console.info(error)
      }
    },

    isAttentionPub () {
      try {
        const openId = this.wxuserInfo.openid
        if (openId) {
          isAttentionPub({
            openId
          }).then((response) => {
            if (
              response.resultCode == 200 &&
              response.data.isAttention == 'OK'
            ) {
              this.isPub = true
            }
          })
        }
      } catch (error) {}
    },

    notAttention () {
      window.location.href =
        'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIwODEzNzE0NA==&scene=110#wechat_redirect'
    },

    async getCode () {
      try {
        // 非静默授权，第一次有弹框
        this.code = ''
        var local = window.location.href // 获取页面url
        // var local = 'http://zy.nndtyun.com/monitor/api/pub/wx/test'
        // var appid = 'wx19b5db029172b30f';//测试公众号
        var appid = 'wx7ff0e2659ee777f6' // 生产公众号
        this.code = this.getUrlCode().code // 截取code

        if (this.code == null || this.code === '') {
          // 如果没有code，则去请求
          const state = '1'
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
            local
          )}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
        } else {
          // console.info('getWx');
          this.getWxUserInfo()
        }
      } catch (error) {}
    },

    // 截取url中的code方法
    getUrlCode () {
      const url = location.search
      this.winUrl = url
      const theRequest = new Object()
      if (url.indexOf('?') != -1) {
        const str = url.substr(1)
        const strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1]
        }
      }
      return theRequest
    },

    // 授权获取微信用户信息
    async getWxUserInfo () {
      try {
        const query = {
          code: this.code
        }
        const response = await getWxUserInfo(query)
        if (response.resultCode == 200) {
          const wxuserInfo = response.data

          if (wxuserInfo.openid) {
            console.info('sava')
            setWxUser(wxuserInfo)
            this.wxuserInfo = wxuserInfo

            this.attention()
          }
        }
      } catch (error) {}
    },
    goInfo (id) {
      this.$router.push({
        path: 'maintenance',
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style scoped>
/* .min {
  background-color: #F9F9F9;
  padding: 0 15px;
} */
.banner {
  width: 100%;
  /* height: 204px; */
  background: url(../../assets/bj.png) no-repeat;
  background-size: 100% 100%;
  padding: 15px;
  margin-top: 5px;
}

.banner .banner-one {
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
}

.banner .banner-too {
  font-size: 13px;
  font-weight: 400;
  color: #ccc;
  margin-top: 10px;
}

.banner .banner-three {
  color: #ffffff;
}

.banner div {
  color: #ffffff;
  font-size: 24px;
}

.banner div span {
  font-size: 13px;
  font-weight: 400;
  color: #ccc;
  margin-right: 6px;
}

.banner div .van-rate {
  margin-left: 5px;
}

.banner .van-button--normal {
  width: 100%;
  height: 39px;
  margin-top: 12px;
  background-color: #fff;
  border-radius: 5px;
}

.banner .van-button__text {
  color: #3369fd;
  font-size: 15px;
  font-weight: 500;
}

.banner img {
  width: 12px;
  height: 12px;
}

.info {
  width: 100%;
  /* height: 270px; */
  background-color: #fff;
  margin-top: 10px;
  border-radius: 8px;
  padding: 15px;
}

.min .info h5,
.title {
  font-size: 16px;
  font-weight: 500;
}

.info .info-outside {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.info .info-item {
  width: 315px;
}

.info .info-item img {
  width: 13px;
  height: 13px;
  position: relative;
  top: 2px;
}

.info .info-item span {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-left: 10px;
}

.info .info-item p {
  margin: 6px 0 0 23px;
  font-size: 13px;
  font-weight: 400;
  color: #383a3d;
}

.min .h5 {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 500;
  color: #383a3d;
}

.main .main-item {
  /* width: 332px;
  height: 84px; */
  background-color: #fff;
  border-radius: 8px;
  margin: 10px;
}

.main-item img {
  width: 50px;
  height: 50px;
  position: relative;
  top: 6px;
  left: -24px;
}

.main-item .item-top {
  font-size: 16px;
  font-weight: 500;
  color: #383a3d;
  position: relative;
  top: -18px;
  left: -20px;
}

.main-item .phone {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-left: 30px;
}

.main-item .phone-number {
  font-size: 13px;
  font-weight: 500;
  color: #383a3d;
}

.main-item .vertical {
  font-size: 14px;
  color: #ccc;
  margin: 0 5px;
}

.main-item-outside {
  display: flex;
  justify-content: space-between;
}

.whole {
  position: relative;
  top: -8px;
  left: -10px;
}

.whole .van-icon {
  position: relative;
  top: 4px;
}
</style>
